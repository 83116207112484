import React from 'react';

// Clients
import gobik from 'images/gobik_logo.svg';
import evvo from 'images/evvo_logo.png';
import claudia_julia from 'images/claudia_julia_logo.png';
import tipitent from 'images/tipitent_logo.png';
import laconicum from 'images/laconicum_logo.png';
import bra from 'images/bra_logo.png';
import singularu from 'images/singularu.svg';
import mediamarkt from 'images/mediamarkt_logo.svg';
import telefonica_logo from 'images/telefonica_logo.svg';
import blue_banana from 'images/blue_banana.png';
import mango from 'images/mango-logo.png';
import marriot from 'images/marriott_Logo.png';
import scalpers from 'images/scalpers_logo.svg';
import styled from 'styled-components';
import {LanguageType} from '../../locales/types';
import {StyledDiv} from './StyledSx';
import {ScreenSizes, Sx} from '../../types/responsive';

export type Client = {
  url: string | LanguageType;
  logo: string;
  alt: string;
  width: number;
  scale: number;
};

type Props = {
  clientList?: Client[];
};

export const defaultClientList: Client[] = [
  {
    logo: mediamarkt,
    url: {
      es: 'https://www.mediamarkt.es/',
      de: 'https://www.mediamarkt.de/',
      pt: 'https://www.mediamarkt.pt/'
    },
    alt: 'Mediamarkt payment gateway',
    width: 300,
    scale: 1
  },
  {
    logo: telefonica_logo,
    url: {
      es: 'https://www.telefonica.es/es/',
      en: 'https://www.telefonica.com/en/'
    },
    alt: 'Telefonica',
    width: 300,
    scale: 1
  },
  {
    logo: mango,
    url: {
      es: 'https://shop.mango.com/es',
      fr: 'https://shop.mango.com/fr',
      de: 'https://shop.mango.com/de',
      en: 'https://shop.mango.com/gb',
      pt: 'https://shop.mango.com/pt',
      it: 'https://shop.mango.com/it'
    },
    alt: 'Mango payment gateway',
    width: 300,
    scale: 1
  },
  {
    logo: marriot,
    url: {
      es: 'https://www.espanol.marriott.com',
      fr: 'https://www.marriott.fr',
      pt: 'https://www.marriott.com.br',
      de: 'https://www.marriott.de',
      it: 'https://www.marriott.it',
      en: 'https://www.marriott.com'
    },
    alt: 'Marriot payment gateway',
    width: 300,
    scale: 0.9
  },
  {
    logo: scalpers,
    url: {
      es: 'https://www.scalpers.com/',
      pt: 'https://pt.scalperscompany.com/',
      fr: 'https://fr.scalperscompany.com/',
      de: 'https://de.scalperscompany.com/',
      en: 'https://en.eu.scalperscompany.com/'
    },
    alt: 'Scalpers payment gateway',
    width: 300,
    scale: 1
  },
  {
    logo: blue_banana,
    url: {
      es: 'https://www.bluebananabrand.com/',
      pt: 'https://www.bluebananabrand.com/pt/',
      fr: 'https://www.bluebananabrand.com/fr/',
      de: 'https://www.bluebananabrand.com/de/',
      en: 'https://www.bluebananabrand.com/en/'
    },
    alt: 'Blue banana payment gateway',
    width: 300,
    scale: 1
  },
  {
    logo: singularu,
    url: {
      es: 'https://singularu.com/',
      pt: 'https://singularu.com/',
      fr: 'https://singularu.com/',
      de: 'https://singularu.com/',
      en: 'https://singularu.com/'
    },
    alt: 'Singularu payment gateway',
    width: 300,
    scale: 1
  },
  {
    logo: gobik,
    url: 'https://gobik.com/',
    alt: 'Gobik payment gateway',
    width: 300,
    scale: 1
  },
  {
    logo: evvo,
    url: 'https://evvohome.es/',
    alt: 'Evvo payment gateway',
    width: 300,
    scale: 1
  },
  {
    logo: claudia_julia,
    url: 'https://www.claudiaandjulia.es/',
    alt: 'Claudia&Julia payment gateway',
    width: 300,
    scale: 1
  },
  {
    logo: tipitent,
    url: 'https://www.thetipitent.com/',
    alt: 'Tipi Tent payment gateway',
    width: 300,
    scale: 1
  },
  {
    logo: laconicum,
    url: 'https://laconicum.es/',
    alt: 'Laconium payment gateway',
    width: 300,
    scale: 1
  },
  {
    logo: bra,
    url: 'https://www.cocinaconbra.com/',
    alt: 'Bra payment gateway',
    width: 300,
    scale: 1
  }
];

const ClientsContainer = styled(StyledDiv)`
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0 2rem;

  @media (max-width: ${ScreenSizes.md}) {
    justify-content: space-evenly;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    flex-direction: column;
  }
`;

const StyledClientLogo = styled.div`
  margin: 0;
  opacity: 0.7;
  transition: 250ms opacity;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;

  :hover {
    opacity: 1;
  }

  img {
    margin: 0;
  }

  @media (max-width: 768px) {
    margin: 0;
    max-width: 100%;
  }
`;

const ClientLogo: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => (
  <StyledClientLogo>
    <img {...props} />
  </StyledClientLogo>
);

export const Clients: React.FC<Props & React.HTMLAttributes<any> & {sx?: Sx}> = ({
  clientList = defaultClientList,
  ...props
}) => {
  return (
    <ClientsContainer {...props}>
      {clientList.map((client) => (
        <ClientLogo src={client.logo} alt={client.alt} width={client.width} />
      ))}
    </ClientsContainer>
  );
};
